const themeGen = theme => { // eslint-disable-line
  const res = {
    excludeSameProductFromShelf: false,
    hideShelfTitleOnMobile: false,
    headerModelComponentSameRow: true,
    showPrinterModelRecommendation: true,
    showVaccumModelRecommendation: false,
    conf: {
      shelfPageProductsOrdering: [
        'oneBuyProducts',
        'optimum',
        'recommended',
        'budget',
        'original',
        'upselling',
        'freeCategories',
      ],
      shelfPageCheckProductSection: ['recommended', 'optimum', 'budget', 'original'],
    },
  };
  return res;
};

export default themeGen;
